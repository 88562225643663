import { useEffect, useState } from 'react';

export const useHeroDismissed = (isDismissedInitial = false) => {
  const [isDismissed, setIsDismissed] = useState(() => {
    const dismissed = localStorage.getItem('dismissed');
    return dismissed ? JSON.parse(dismissed) : isDismissedInitial;
  });

  useEffect(() => {
    localStorage.setItem('dismissed', JSON.stringify(isDismissed));
  }, [isDismissed]);

  return [isDismissed, setIsDismissed];
}

export default useHeroDismissed;
