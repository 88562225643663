import React, { useEffect } from 'react';
import styled from 'styled-components' // TODO: Delete if unused

const TickerStreamContainer = styled.div`

        margin: auto;

        body {
            font-family: 'LcdSolid', monospace;
        }

        position: absolute;
        
        @font-face {
            font-family: 'LcdSolid';
            src: url('./fonts/LcdSolid.ttf') format('truetype');
        }

        .ticker {
            /* width: fit-content; */
            overflow: hidden;
            font-family: 'LcdSolid', monospace;
            margin-bottom: 4px;
            width: fit-content;
        }

        .ticker.positive {
            color: #00b500;
        }

        .ticker.negative {
            color: #c70101;
        }

        .ticker div {
            white-space: nowrap;
        }

        /* .ticker.positive div {
            animation: ticker-positive 180s linear infinite;
            position: relative;
        }

        .ticker.negative div {
            animation: ticker-negative 180s linear infinite;
            position: relative;
        } */
`;

function TickerStream() {
    function splitIntoChunks(array, chunkSize) {
        var chunks = [];
        for (var i = 0; i < array.length; i += chunkSize) {
            chunks.push(array.slice(i, i + chunkSize));
        }
        return chunks;
    }

    const SPEED_CONSTANT = 25; // 100px per second

    function animateTicker(element, positive = true, elementId) {
      // Measure the container width
      let startingOffset = element.parentElement.parentElement.parentElement.offsetWidth;
      let startingWidth = element.scrollWidth  - window.innerWidth + (window.innerWidth - startingOffset) + 5;
      console.log(window.screen.width, startingWidth)


      // Set the id of the element to elementId
      element.id = "ticker" + elementId;
      const width = (element.scrollWidth / 3);
    
      // Calculate the animation duration based on the width and SPEED_CONSTANT
      let duration = width / SPEED_CONSTANT;

    //   duration = duration * 0.6
    
      // Create a style element to host the keyframe
      const style = document.createElement('style');
    //   const animationName = positive ? 'ticker-positive' : 'ticker-negative';
    
      element.style.position = 'relative';
    //   element.style.right = positive ? `-${50}px` : `${startingWidth}px`;
    //   element.style.right = 0

    //   const adjustment = window.screen.width;
      // Define the keyframes with the calculated width based on the direction
      let animationName = "ticker" + elementId;
      style.innerHTML = `

        @keyframes ${animationName} {
          0% {
            transform: translateX(${positive ? (0) : -(element.scrollWidth / 3)}px);
          }
          100% {
            transform: translateX(${positive ? -(element.scrollWidth / 3) : (0)}px);
          }
        }
      `;
    
      // Append the style to the document head
      document.head.appendChild(style);

      // Set the right property directly on the element
    
      // Apply the animation to the element
      element.style.animation = `${animationName} ${duration}s linear infinite`;

      console.log("Duration per character", duration / element.innerHTML.length, "duration", duration, "width", width, "speed constant", SPEED_CONSTANT)
    }
    
  const fetchAndDisplayData = () => {
    console.log("Fetching new ticker data")
    fetch('https://feed.tkn.xyz/prices/page/all')
        .then(response => response.json())
        .then(data => {
            console.log("Fetched new ticker tape data")
            // Create ticker tape strings
            var positiveTickerElements = document.getElementsByClassName("positive-ticker");
            var negativeTickerElements = document.getElementsByClassName("negative-ticker");
            console.log("Positive tickers: " + positiveTickerElements.length, "Negative tickers: " + negativeTickerElements.length)
            var positiveTickers = []
            var negativeTickers = []
            Object.keys(data).forEach(function (ticker) {
                let parsedTickerData = data[ticker];
                if (parsedTickerData.PRICE) {
                    var tickerPrice = data[ticker] ? parsedTickerData.PRICE.replace(' ', '') : 'N/A';
                    var change = data[ticker] ? data[ticker].CHANGEPCT24HOUR : 0;
                    if (!change.includes('-')) {
                        change = "+" + change
                    }
                    var tickerData = ticker + ": " + tickerPrice + " " + change + "% ";
                    if (change >= 0) {
                        positiveTickers.push(tickerData);
                    } else {
                        negativeTickers.push(tickerData);
                    }
                } else {
                    console.log("No price data for " + ticker)
                }
            });
            console.log("Positive ticker data: " + positiveTickers.length, "Negative ticker data: " + negativeTickers.length)

            // divide positiveTickers by the number of positive-ticker elements and create a string for each element
            let positiveBatchSize = Math.ceil(positiveTickers.length / positiveTickerElements.length);
            let negativeBatchSize = Math.ceil(negativeTickers.length / negativeTickerElements.length);

            let positiveTickerChunks = splitIntoChunks(positiveTickers, positiveBatchSize);
            let negativeTickerChunks = splitIntoChunks(negativeTickers, negativeBatchSize);

            let tickerCount = 0;
            let posTapeCount = 0;
            for (const positiveTickerTape of positiveTickerElements) {
                let positiveTickerTapeString = positiveTickerChunks[posTapeCount].join(' ');

                // Add twice for a clean "reset" animation
                positiveTickerTape.innerHTML = positiveTickerTapeString + " " + positiveTickerTapeString + " " + positiveTickerTapeString;

                animateTicker(positiveTickerTape, true, tickerCount);
            
                // console.log("Rendered", posTapeCount, "positive ticker tape")
                // animate

                posTapeCount++;
                tickerCount++;
            }
            let negTapeCount = 0;
            for (const negativeTickerTape of negativeTickerElements) {
                let negativeTickerTapeString = negativeTickerChunks[negTapeCount].join(' ');

                // Add twice for a clean "reset" animation
                negativeTickerTape.innerHTML = negativeTickerTapeString + " " + negativeTickerTapeString + " " + negativeTickerTapeString;
            
                // animate
                animateTicker(negativeTickerTape, false, tickerCount);

                negTapeCount++;
                tickerCount++;
            }
            console.log("Ticker tapes rendered",[Date.now()].concat(arguments))
            
        })
        .catch((error) => {
            console.error('Error:', error);
        });
  }

  // Use the useEffect hook to call fetchAndDisplayData when the component is mounted
  useEffect(() => {
    console.log("Running fetchAndDisplayData",[Date.now()].concat(arguments))
    fetchAndDisplayData();
    const interval = setInterval(fetchAndDisplayData, 5 * 60 * 1000);
    
    // Clear the interval when the component is unmounted
    return () => clearInterval(interval);
  }, []);

  return (
    <TickerStreamContainer style={{ opacity: '0.6' }}>
        <div className="ticker positive">
            <div className="positive-ticker"></div>
        </div>
        <div className="ticker negative">
            <div className="negative-ticker"></div>
        </div>
        <div className="ticker positive">
            <div className="positive-ticker"></div>
        </div>
        <div className="ticker negative">
            <div className="negative-ticker"></div>
        </div>
        <div className="ticker positive">
            <div className="positive-ticker"></div>
        </div>
        <div className="ticker negative">
            <div className="negative-ticker"></div>
        </div>
        <div className="ticker positive">
            <div className="positive-ticker"></div>
        </div>
        {/* <div className="ticker negative">
            <div  className="negative-ticker"></div>
        </div> */}
      {/* Add more tickers if needed */}
    </TickerStreamContainer>
  );
}

export default TickerStream;