// store.js
import { createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

const initialState = {
  favoritesArray: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_FAVORITE':
      const uppercasePayload = action.payload.toUpperCase();
      if (!state.favoritesArray.includes(uppercasePayload)) {
        return { ...state, favoritesArray: [...state.favoritesArray, uppercasePayload] };
      }
      return state;
    case 'REMOVE_FAVORITE':
      const itemToRemove = action.payload.toUpperCase();
      return {
        ...state,
        favoritesArray: state.favoritesArray.filter((item) => item !== itemToRemove),
      };
    default:
      return state;
  }
};

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = createStore(persistedReducer);
export const persistor = persistStore(store);
