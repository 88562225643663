import { createGlobalStyle } from "styled-components";
import LcdSolid from "./fonts/LcdSolid.ttf";

const FontStyles = createGlobalStyle`

@font-face {
  font-family: 'LcdSolid';
  src: url(${LcdSolid}) format('ttf');
}
`;

export default FontStyles;